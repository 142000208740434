// 各服务地址
let SERVER_PATH = process.env.REACT_APP_SERVER_PATH; //'http://localhost:8000/';//
let WECHAT_AUTH_API = "http://ycim-wx-api-dev.gaoguangyu.com/";
let WECHAT_APPID = "";

// 数据交互配置
const TIMEOUT = 25000; //请求超时时间

/*DSBridge*/
/*global getJsBridge()*/
/*let bridge = getJsBridge();*/

const base = {
    header: {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    },
};

const ALERT_LEVELS = [
    {
        level: 0,
        name:'一般',
        color:'#30A5FF'
    },
    {
        level: 1,
        name:'较重',
        color: "#FABE28"
    },
    {
        level: 2,
        name:'严重',
        color: '#F36A5A'
    }
]

/*
 * 接口配置部分
 */
const Config = {
    authApi:{
        API_SEND_MOBILE_CHECK_CODE: SERVER_PATH + 'api/send-mobile-check-code', //让服务器发送短信校验码
        API_VERIFY_MOBILE_CHECK_CODE: SERVER_PATH + 'api/verify_mobile_check_code', //校验登录验证码
        API_UPDATE_USER_INFO_TO_PROJECT: SERVER_PATH + 'api/update_user_info_to_project', //更新用户信息到指定项目
        
        API_GET_UPLOAD_TOKEN : SERVER_PATH + 'api/oss-upload-token/', //获取图片权限的上传token
        API_TOKEN_AUTH : SERVER_PATH + 'api-token-auth/',   // 登陆接口
        API_TOKEN_REFRESH : SERVER_PATH + 'api-token-refresh/',   // 刷新token
        API_GET_JSSDK_SIGNATURE : SERVER_PATH + 'wechat/scanqr/', //获取JS-SDK的签名
        API_WECHAT_BIND_MOBILE : SERVER_PATH + 'api/wechat-bind-mobile',
        API_GET_MY_PERMISSIONS : SERVER_PATH + 'api/get_my_permissions',
        API_AUTHORIZE_REMOTE_CTRL_AUTH: SERVER_PATH + 'api/authorize_remote_ctrl_auth',
        API_AUTH_LOGS: SERVER_PATH + 'api/auth_logs',
        API_PASSWORD_LOGIN : SERVER_PATH + 'api/password_login',
    },
    mgrApi:{
        API_USERS: SERVER_PATH + "api/users",//users
    },
    deviceApi:{
        API_DATA_METAS: SERVER_PATH + "api/data_metas",
        API_DATA_ITEMS: SERVER_PATH + "api/data_items",
        API_DEVICES: SERVER_PATH + "api/devices",
        API_DEVICE_PARTS: SERVER_PATH + "api/device_parts",
        API_REMARK_RECORDS: SERVER_PATH + "api/assist_dialogues",
        API_WORK_SHEETS: SERVER_PATH + "api/work_sheets",

        API_WORK_SHEET_METAS: SERVER_PATH + "api/work_sheet_metas",
        API_GET_WORK_SHEET_RECORDS: SERVER_PATH + "api/work_sheet/get_data_records",
        API_SAVE_WORK_SHEET_RECORDS: SERVER_PATH + "api/work_sheet/save_data_records",

        API_ALERT_EVENTS: SERVER_PATH + "api/alert_events",
        API_ALERT_EVENTS_ALL: SERVER_PATH + "api/alert_events/all",
        API_LATEST_DATA_ITEM: SERVER_PATH + "api/latest-data-item",
        API_GET_LATEST_DATA_ITEMS: SERVER_PATH + "api/get-latest-data-items",
        API_GET_LATEST_DATA_ITEM_BEFORE_TIME: SERVER_PATH + "api/get_latest_data_item_before_time",
        API_GET_LATEST_DEV_STATUS_BEFORE_TIME: SERVER_PATH + "api/get_latest_dev_status_before_time",
        API_ALERT_METAS: SERVER_PATH + "api/alert_metas",
        API_ALERT_SUGGS: SERVER_PATH + "api/alert_suggs",
        API_ASSISTS: SERVER_PATH + "api/assists",
        API_SAVE_DATA_ITEMS: SERVER_PATH + "api/save-data-items",
        API_SAVE_DATA_RECORDS: SERVER_PATH + "api/save_data_records",
        API_GET_DAILY_ALERT_NUM_DUR: SERVER_PATH + "api/get-daily-alert-num-dur",
        API_GET_TOTAL_ALERT_NUM: SERVER_PATH + "api/get-total-alert-num",
        API_GET_TOTAL_ALERT_DUR: SERVER_PATH + "api/get-total-alert-dur",
        API_DEV_ACTION_EVENTS: SERVER_PATH + "api/dev_action_events",
        API_GET_RAW_DEV_STATUS_ITEMS: SERVER_PATH + "api/get-raw-dev-status-items",
        API_DEV_STATUS_METAS: SERVER_PATH + 'api/dev_status_metas',
        API_DEV_STATUS_DURS: SERVER_PATH + 'api/dev_status_durs',
        API_GET_STATUS_ENUM_IN_TIME_PERIOD: SERVER_PATH + 'api/get-status-enum-in-time-period',

        //设备管理配置信息
        API_DEVICE_DIAGRAMS: SERVER_PATH + "api/device_diagrams",
        API_GET_SELF_DIAGRAM_CONFIG: SERVER_PATH + "api/get_self_diagram_config",

        API_CHART_GET_TOTAL_INPUT: SERVER_PATH + "api/chart-statistics/get_total_input",
        API_CHART_GET_AL_OUTPUT: SERVER_PATH + "api/chart-statistics/get_al_output",
        API_CHART_GET_GAS_ITPUT: SERVER_PATH + "api/chart-statistics/get_gas_input",
        API_CHART_GET_GAS_ITPUT_PER_AL: SERVER_PATH + "api/chart-statistics/get_gas_input_per_al",
        API_CHART_GET_BURN_LOSS_RATE: SERVER_PATH + "api/chart-statistics/get_burn_loss_rate",
        API_CHART_GET_BATCH_DATA_ITEMS: SERVER_PATH + "api/chart-statistics/get_batch_data_items",

        API_DATA_RECORDS: SERVER_PATH + "api/data_records",
        // API_DATA_RECORD_TEXT:  SERVER_PATH + "api/save_data_record_text",
        API_GET_WORK_SHEET_OF_DEVICE: SERVER_PATH + "api/get_work_sheet_of_device",

        API_GET_SHEET_DATA_RECORDS: SERVER_PATH + "api/work_sheet/get_sheet_data_records",

        API_GET_WORKSHOPS: SERVER_PATH + "api/org_workshops",
        API_GET_SECTIONS: SERVER_PATH + "api/org_sections",
        API_GET_SHIFT_TEAMS: SERVER_PATH + "api/org_shift_teams",
        API_GET_DETAILED_DATA_RECORDS: SERVER_PATH + "api/get_detailed_data_records",
        API_GET_ALERT_STATISTICS_PARTITION: SERVER_PATH + "api/get_alert_statistics_partition",
        API_GET_TOP_ALERT_STATISTICS: SERVER_PATH + "api/get_top_alert_statistics",

        API_WORKER_POSITIONS: SERVER_PATH + "api/worker_positions",
        API_SAVE_DATA_RECORDS_PER_SHIFT: SERVER_PATH + "api/save_data_records_per_shift",

        API_GET_ALERT_LEVEL_SUBS: SERVER_PATH + "api/get_alert_level_subs",
        API_SET_ALERT_LEVEL_SUBS: SERVER_PATH + "api/set_alert_level_subs",
    }
};

export{
    base,
    TIMEOUT,
    SERVER_PATH,
    WECHAT_APPID,
    WECHAT_AUTH_API,
    Config,
    ALERT_LEVELS,
}

export default Config
