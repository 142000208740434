import React, { Component } from 'react';
import Config from '../../common/config.js';
import { Modal, Button, WhiteSpace } from 'antd-mobile';
import md5 from 'md5';
import { loadMyPermissions } from '../../common/AuthPermission.js';
import { getQueryString, getMyId, post, patch, getAndStoreToken } from '../../common/utils.js';
import "./LoginRegister.css";

const PWD_LOGIN_ENABLED = false;

//说明：该页面用以记录token或跳转注册页面
//      目前仅支持跳转到微信浏览器的注册页面
export default class LoginRegister extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkCodeCountNum: 0,
            mobile: "",
            checkCode: "",
            password: "",
            countHandler: null,
            hintText: "",
            heightResize: 0,
            isSmallWindow: false,
            projectList: null,
            sending: false,
            env: getQueryString('env'),
            loginType: PWD_LOGIN_ENABLED ? 'PASSWORD':'CHECK_CODE', // CHECK_CODE or PASSWORD
        };
        // this.updateSize = this.updateSize.bind(this);
        this.setTokenOrOpenId(props);
    }

    setTokenOrOpenId(props) {
        let token = getQueryString("token");
        console.log(token);
        if (token) {
            window.localStorage.setItem('USER_INFO', JSON.stringify({
                user_id: "",
                token: token,
                time: new Date().getTime(),
                expire_time: new Date().getTime()/1000 + 3600*30,
            }));
        }

        // 1. 如果返回token，表示鉴权成功，跳转至相应页面。
        // if (token != null) {
        //     getAndStoreToken();
        //     loadMyPermissions().then(() => {
        //         // props.history.replace(window.decodeURIComponent(encodedCallbackPage));
        //     }).catch(
        //         // () => alert('权限信息获取失败，请刷新重试')
        //     );
        // }
    }

    startCounting = ()=>{
        var self = this;
        self.setState({ checkCodeCountNum: 60 });
        this.state.countHandler = window.setInterval(function () {
            if (self.state.checkCodeCountNum - 1 <= 0) {
                if (self.state.countHandler != null) {
                    window.clearInterval(self.state.countHandler);
                    self.state.countHandler = null;
                }
            }
            self.setState({ checkCodeCountNum: self.state.checkCodeCountNum - 1 });
        }, 1000);
    }

    sendCheckCode = ()=>{
        if (this.state.countHandler != null) {
            return;
        }

        if(this.state.mobile.indexOf('1590000') >= 0){
            alert('测试账号只能支持密码方式登陆，请切换为“用密码登陆”');
            return;
        }

        let self = this;
        this.setState({sending: true});
        post(process.env.REACT_APP_SERVER_PATH + 'api/send_mobile_check_code', {
            // encodedOpenId: getQueryString("encodedOpenId"),
            mobile: this.state.mobile,
            env: this.state.env
        }).then((retData) => {
            self.setState({sending: false});
            console.log(retData);
            if (retData.error == true) {
                alert("发送失败");
            } else if (retData && retData.data && retData.data.errcode == 0) {
                this.startCounting();
                self.setState({ hintText: "发送成功" })
            } else if (retData && retData.data && retData.data.errcode == 404) {
                alert("该账号不存在，请联系管理员。");
            } else {
                alert("发送失败。");
            }
        });
    }

    componentWillMount() {
    }

    componentDidMount() {
        var self = this;
        // setTimeout(function () {
        //     if (self.state.heightResize == 0 && window.innerHeight != 0) {
        //         self.setState({
        //             heightResize: window.innerHeight,
        //         });
        //     }
        // }, 0);
        // window.addEventListener("resize", this.updateSize);
    }
    // updateSize() {
    //     if (window.innerHeight >= this.state.heightResize) {
    //         this.setState({
    //             isSmallWindow: false
    //         });
    //     } else if (window.innerHeight < this.state.heightResize) {
    //         this.setState({
    //             isSmallWindow: true
    //         });
    //     }
    //     console.log("updated");
    // }

    loginByMobile = ()=>{
        var self = this;
        if (self.state.projectList != null) {
            self.alertProjectList();
            return;
        }
        if (self.state.checkCode == "") {
            alert("请输入验证码");
            return;
        }
        post(process.env.REACT_APP_SERVER_PATH + 'api/login_by_sms_code', {
            check_code: self.state.checkCode,
            mobile: self.state.mobile,
            env: this.state.env,
        }).then((retData) => {
            // console.log(retData);
            if (retData.error == true) {
                alert("校验失败");
            } else if (retData && retData.data.errcode == 0) {
                self.setState({ projectList: retData.data.project_list }, _ => {
                    self.alertProjectList();
                });
            } else {
                alert("校验失败");
            }
        })
        return;//for test
    }

    loginByPassword = _=>{
        let self = this;
        post(Config.authApi.API_PASSWORD_LOGIN,{
            username: self.state.mobile,
            password: md5(self.state.password),
            env: self.state.env,
        }).then((retData)=>{
            console.log(JSON.stringify(retData));
            if(retData.error == true){
                alert("登陆失败 x");
            }else if(retData && retData.data && retData.data.errcode == 0){
                self.setState({ projectList: retData.data.project_list }, _ => {
                    self.alertProjectList();
                });
            }else{
                alert("登陆失败，用户名或密码错误");
            }
        })
    }

    gotoProject = project_key => {
        let self = this;
        post(process.env.REACT_APP_SERVER_PATH + 'api/update_user_info_to_project', {
            project_key: project_key,
            env: self.state.env
        }).then((retData) => {
            // console.log(retData);
            if (retData.error == true) {
                alert("跳转失败");
            } else if (retData && retData.data.errcode == 0) {
                window.location.replace(retData.data.redirect_url);
            } else {
                alert("跳转失败");
            }
        })

    }

    alertProjectList() {
        let self = this;
        console.log("alertProjectList");
        if (self.state.projectList.length == 1) {
            //如果只有一项，直接跳转
            self.gotoProject(self.state.projectList[0].project_key);
            return;
        }
        Modal.alert('跳转至项目', <div>
            {
                self.state.projectList.map((item, index) => [
                    <WhiteSpace key={'WhiteSpace' + index} />,
                    <Button key={index} onClick={_ => self.gotoProject(item.project_key)} style={{ color: '#108ee9' }}>{item.name}</Button>
                ])
            }
        </div>, [{ text: '取消', onPress: () => console.log('取消按钮被点击了'), style: { color: 'grey' } }])

    }

    render() {
        var self = this;
        return (
            <div className="LoginRegisterWrapper">
                <div className="LoginWrapper" style={{ height: "100%" }}>
                    <div className="downWrapper">
                        <div className="twoLineWrapper">
                            <div className="mobileLine">
                                <div className="textHint">手机号</div>
                                <input placeholder="请输入您的手机号" type="tel" value={this.state.mobile} onChange={(e) => this.setState({ mobile: e.target.value, hintText: "" })} />
                            </div>
                            <div className="textUnderline"></div>
                            {
                                this.state.loginType == 'CHECK_CODE' &&
                                <div className="checkCodeLine">
                                    <div className="textHint">验证码</div>
                                    <input placeholder="请输入短信验证码" type="tel" value={this.state.checkCode} onChange={(e) => this.setState({ checkCode: e.target.value, hintText: "" })} />
                                    {
                                        this.state.sending == false &&
                                        <div className="checkCodeBtn" onClick={this.sendCheckCode}>{this.state.checkCodeCountNum == 0 ? "发送短信验证码" : (this.state.checkCodeCountNum + "秒后重新获取")}</div>
                                    }
                                    {
                                        this.state.sending == true &&
                                        <div className="checkCodeBtn">&nbsp;发送中...&nbsp;</div>
                                    }
                                </div>
                            }
                            {
                                this.state.loginType == 'PASSWORD' &&
                                <div className="checkCodeLine">
                                    <div className="textHint">密&nbsp;&nbsp;&nbsp;码</div>
                                    <input placeholder="请输入您的密码" type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value, hintText: "" })} />
                                </div>
                            }
                        </div>
                        <div className="hintText">{this.state.hintText}</div>
                        <div
                            className="loginBtn"
                            style={{ display: (this.state.isSmallWindow ? "none" : "block") }}
                            onClick={()=>{
                                if(self.state.loginType == 'PASSWORD'){
                                    self.loginByPassword();
                                }else{
                                    self.loginByMobile();
                                }
                            }}
                        >
                            立即登陆
                        </div>
                        {
                            PWD_LOGIN_ENABLED == true &&
                            <div
                                className="loginSwitch"
                                onClick={()=>this.setState({loginType: (this.state.loginType == 'CHECK_CODE'?'PASSWORD':'CHECK_CODE')})}
                            >
                                {this.state.loginType == 'CHECK_CODE'?'用密码登陆':'用验证码登陆'}
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
