// import queryString from 'query-string';
// import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import {WECHAT_APPID} from './config.js';

/*import Mock from 'mockjs';*/
import Config,{ TIMEOUT,SERVER_PATH, WECHAT_AUTH_API } from './config';
//import { hashHistory } from 'react-router';

// function get(url, params) {
//     if (params) {
//         url += '?' + queryString.stringify(params)
//     }
//     return fetch(url)
//         .then((res) => res.json())
//         /*.then((res) => Mock.mock(res))*/
// }
function get(url,params){
    return axiosFn(url,params,'get');
}

function post(url, data) {
    return axiosFn(url,data,'post');
}

function put(url, data) {
    return axiosFn(url,data,'put');
}

function patch(url, data) {
    return axiosFn(url,data,'patch');
}

function reqDelete(url, data) {
    return axiosFn(url,data,'delete');
}


function axiosFn(url,paramsData,method){
    let config ={
        url: url,
        method: method,
        timeout: TIMEOUT,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    };

    if(getToken() != null){
        config.headers['Authorization'] = getToken();
    }

    if(method === "get"){
        config.params = paramsData;
    }else{
        config.data = paramsData;
    }

    return axios(config).then(res=>{
        return res;
    }).catch(e=>{
        let res = e.response;
        if(res && res.status === 401 && res.data && res.data.errcode == 401){
            window.sessionStorage.clear();
            //此处先处理SupOS的情况
            if(!window.reauthing){
                window.reauthing = true;
                notification.info({ message: '刷新凭证，请稍等...', duration: 1.5});
                setTimeout(()=>{
                    window.location.hash = '/AppAuth';
                },800);
            }
        }
        return {error:true}
        // if(e.response){

        // }else{
        //     //在设置触发错误的请求时发生了错误
        //     console.log('Error',e)
        // }
        // console.log(e.config);
        // return {error:true}
    });
}

function getAndStoreTmpToken(){
  var tmpToken = getQueryString("tmpToken");
  if(tmpToken && tmpToken != null && tmpToken != ""){
    window.localStorage.setItem("tmpTokenWithTimestamp",tmpToken+","+new Date().getTime());
  }
}

function getAndStoreToken(){
    let token = getQueryString("token");
    let userId = getQueryString("user_id");
    if (!userId) userId = "-1";
    if(token && token != null && token != "" && userId && userId != null && userId != ''){
        window.localStorage.setItem('USER_INFO', JSON.stringify({
            user_id: userId,
            token: token,
            time: new Date().getTime(),
        }));
    }
}

//获取URL中参数值
function getQueryString(name, _hash) {
    /*var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return (r[2]);
    return null;*/
    let hash = _hash;
    if(!hash){
        hash = window.location.hash;
    }

    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = hash.substr(hash.indexOf('?')+1).match(reg);
    if (r != null) return (r[2]);
    return null;
}

//localStorage
let storageInfo = (function() {
  return {
    set: function(name, value, expires, path, domain) {
      if (window.localStorage) {
        window.localStorage.setItem(name, value);
      } else {
        if (typeof expires === "undefined") {
          expires = new Date(new Date().getTime() + (10000000 * 60 * 60 * 24 * 30)).toGMTString();
        }
        document.cookie = name + "=" + escape(value) + ((expires) ? ";expires=" + expires : "") + ((path) ? ";path=" + path : ";path=/") + ((domain) ? ";domain=" + domain : "");
      }
    },
    get: function(name) {
      if (window.localStorage) {
        return window.localStorage.getItem(name);
      } else {
        let arr = document.cookie.match(new RegExp("(^| )" + name + "=([^;]*)(;|$)"));
        if (arr != null) {
          return unescape(arr[2]);
        }
        return null;
      }
    },
    clear: function(name, path, domain) {
      if (window.localStorage) {
        window.localStorage.removeItem(name);
      } else {
        if (this.get(name)) {
          document.cookie = name + "=" + ((path) ? "; path=" + path : "; path=/") + ((domain) ? "; domain=" + domain : "") + ";expires=Fri, 02-Jan-1970 00:00:00 GMT";
        }
      }
    }
  }
})()

//操作cookie
let operateCookie = (() => ({
    /*set: function(name,value,time) {
        let strsec = getsec(time);
        let exp = new Date();
        exp.setTime(exp.getTime() + strsec*1);
        document.cookie = name + "="+ escape (value) + ";expires=" + exp.toGMTString();
    },*/
    get: function(name) {
        let arr,reg=new RegExp("(^| )"+name+"=([^;]*)(;|$)");
        if(arr===document.cookie.match(reg)) return unescape(arr[2]);
        else return null;
    },
    delete: function(name) {
        let exp = new Date();
        exp.setTime(exp.getTime() - 1);
        let cval=this.get(name);
        if(cval!=null)
        document.cookie= name + "="+cval+";expires="+exp.toGMTString();
    }
}))()

function goPage(url){
  //hashHistory.push(url);
}
// 返回按钮事件
function goBack(){
  //hashHistory.goBack();
}
function goBack2(){
  bridge().call('nativePop', {msg:'success'});
}

function getMyId(){
	// var token = getToken();
	// if(token != null){
	// 	return token;
	// }
    let userInfo = window.localStorage.getItem('USER_INFO');
	if(userInfo == null){
        return null;
    }
    userInfo = JSON.parse(userInfo);
    if(new Date().getTime() - userInfo.time > 24 * 60 * 60 * 1000){//token超过6小时即失效
        return null;
    }
	return userInfo.user_id;
}

//获取javascript bridge对象
function bridge() {
  /*global getJsBridge*/
  if(window.getJsBridge){
    return getJsBridge();
  }else{
    return new Function();
  }
}

function getCachedUserInfo() {
    let userInfo = null;
    // if(window.localStorage.getItem('PERSIST_SESSION') == 'true'){
    //     userInfo = window.localStorage.getItem('USER_INFO');
    // }else{
    //     userInfo = window.sessionStorage.getItem('USER_INFO');
    // }
    userInfo = window.localStorage.getItem('USER_INFO');
    if(userInfo){
        userInfo = JSON.parse(userInfo);
    }
    if(!userInfo || new Date().getTime()/1000 > userInfo.expire_time){//token超过6小时即失效
        return null;
    }
    return userInfo;
}

//获取token
function getToken() {
    let userInfo = getCachedUserInfo();
    // if (
    //     window.location.hash.indexOf('/Login') !== -1
    //     || window.location.hash.indexOf('/ExcelDemo') !== -1
    // ) return null;
    if (!!userInfo) {
        const { expire_time } = userInfo;
        try {
            console.log(expire_time);
            console.log(moment.unix(expire_time))
            console.log(moment());
            if (moment.unix(expire_time).isAfter(moment())) {
                return userInfo.token;
            };
        } catch (err) { };
    }

    return null;
}

// 登录
function goLogin() {
    //return bridge().call("goLogin");
}

/*
 * prefix  --  文件前缀
 */
function getFileInfo(prefix){
    //let params = {};
    //params.prefix = prefix;
    //return get(Config.commonApi.getQiniuToken,params)
}

/*
  判断是否在微信中
*/
function isWechat(){
    var ua = navigator.userAgent.toLowerCase();
    return ua.match(/MicroMessenger/i)=="micromessenger";
}

function toRedirect(){
/*  var redirectUrl = window.localStorage.getItem("redirectUrl");
  if(redirectUrl){
    window.localStorage.removeItem("redirectUrl");
    hashHistory.push(redirectUrl);
  }*/
}

function callWeChatAuth(callbackUrl){
	//alert("callWeChatAuth");
  if(!(/micromessenger/i).test(navigator.userAgent)){
    return;
  }
  callbackUrl = callbackUrl || window.location.href;
  var authUrl = "https://open.weixin.qq.com/connect/oauth2/authorize?appid="
                  + WECHAT_APPID
                  + "&redirect_uri="+WECHAT_AUTH_API+"wechat/code-to-token/&response_type=code&scope=snsapi_base&state="
                  + encodeURIComponent(window.location.href.replace(window.location.hash, "")+"#/wxauth/"+encodeURIComponent(callbackUrl))
                  + "#wechat_redirect";
  //alert(authUrl);
  window.location.replace(authUrl);
}

function formatSeconds(seconds){
    if(parseInt(seconds/60)<1){
        return seconds+'秒'
    }else{
        return parseInt(seconds/60)+'分钟'+(seconds%60)+'秒'
    }
}

function nativeReload() {
    return bridge().call("reload");
}

function concatValueUnit(value, unit){
    if(unit == 's' || unit == '秒'){
        return formatSeconds(value);
    }else{
        if(unit == '%' && typeof(value) == 'number'){
            value = value * 100;
        }
        if(unit == '%'){
            return value+(unit||'');
        }else{
            return value+' '+(unit||'');
        }
    }
}

function isIOS(){
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('iphone') > 0 || ua.indexOf('ipad') > 0) {
        return true;
    }else{
        return false;
    }
}

window.clickElem=function(elem){
  document.querySelector("#"+elem).click();
}
export {
  get,
  post,
  reqDelete,
  patch,
  isIOS,
  getQueryString,
  storageInfo,
  operateCookie,
  bridge,
  goLogin,
  goPage,
  goBack,
  goBack2,
  getToken,
  getFileInfo,
  toRedirect,
  nativeReload,
  callWeChatAuth,
  getMyId,
  getAndStoreToken,
  formatSeconds,
  concatValueUnit,
  isWechat,
}
